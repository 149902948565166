import { Avatar } from "../containers/ImageContainer";
import { LinkDark, LinkSecodary } from "../links/Links";
import { ArticleHeader, PageSubHeader, Paragraph3, SectionSubheader } from "../typography/Headers";
import { Ellipsis } from "../utilities/Helpers";
import { Block } from "../utilities/Span";
import { sectionSubheader } from "../../utilities/typography";
import { placeholder } from "../../utilities/helpers";
import { DefaultButton, ButtonTertiary, Span } from "@medforall/medforall-component-library";
import { CSSProperties, useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context";
import { getGraphImage, getGraphProfile } from "../../apicalls/graphService";
import GraphProfile from "../../types/graphProfile";
import useImageOnLoad from "../../hooks/useImageOnLoad";
import { getAPIToken, parseJwt } from "../../apicalls/billingService";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ButtonHelp } from "../buttons/Buttons";


const MainNavOld = props => {
	return <>
		<div className="tw-flex tw-flex-col || tw-bg-white tw-flex-grow tw-h-screen">
			<div className="tw-flex-shrink tw-bg-white tw-border-b tw-border-light-accent/70 tw-px-6">
				<PageSubHeader tag="h2" className="tw-dark tw-py-xl">
					Service <Block>Management Site</Block>
				</PageSubHeader>

				<div className="tw-pb-lg">
					<div className="c-hover-toggle c-hover-toggle__trigger">
						<div className="tw-flex tw-items-center">
							<div className="tw-flex-shrink-0 tw-w-12 tw-mr-sm">
								<Avatar src={ placeholder('200/000000/FFFFFF?text=User') } />
							</div>
							<div className="tw tw-min-w-0">
								<div className="c-hover-toggle__trigger || tw-w-full">
									<PageSubHeader tag="h3" className="tw-dark tw-max-w-full">
										<span className="tw-block tw-max-w-full">
											<span className="tw-flex tw-items-baseline">
												<Ellipsis>
													Kevin
												</Ellipsis>
												<span className="tw-flex-shrink-0">, M</span>
											</span>
										</span>
									</PageSubHeader>
								</div>
							</div>
						</div>
						<div className="c-hover-toggle__element || tw-top-0 tw-right-0 ">
							<DefaultButton className="tw-p-0" childClasses="tw-pr-0">
								<span className="tw-bg-white/50 tw-backdrop-blur-sm tw-p-sm tw-flex tw-justify-start tw-items-center tw-text-left tw-w-full tw-w-full tw-min-h-30px tw-px-xs tw-text-6 tw-border tw-text-red-600 hover:tw-text-red-800">Logout</span>
							</DefaultButton>
						</div>
					</div>
					<Paragraph3 className="tw-mt-xs" tag="div" childTag="div">
						<LinkDark href="mailto:kmack@discreetaf.com" target="_blank" title="kmack@discreetaf.com">
							<Ellipsis>
								kmack@discreetaf.com
							</Ellipsis>
						</LinkDark>
					</Paragraph3>
				</div>
			</div>
			<div className="tw-flex-grow tw-flex-shrink tw-overflow-y-auto">
				<div className="tw-pt-lg tw-px-6 tw-py-3">
					<LinkSecodary to="/">
						<SectionSubheader tag="span" childClasses="tw-font-bold">
							<Ellipsis>
								Senior Health Care Agency
							</Ellipsis>
						</SectionSubheader>
					</LinkSecodary>
				</div>
				<ul className=" tw-list-none tw-flex tw-flex-col">

					<li>
						<LinkDark
							onClick={ e => {
								e.preventDefault();
								e.target.classList.toggle('_active');
							}}
							href="#"
							className={`${sectionSubheader}|| tw-flex tw-w-full  || c-toggle-menu-item _active`}
						>
							<span className="tw-font-semibold || tw-flex-grow tw-flex tw-items-center  tw-justify-between || tw-mx-6 tw-py-xl || tw-pointer-events-none || tw-border-b tw-border-light">
								<span className="tw-flex tw-flex-grow tw-shrink-1">
									Schedule Management
								</span>
								<span className="tw-flex tw-shrink-0 ">
									<span className="t-ml-3 tw-transition tw-ease-in-out tw-inline-block tw-text-primary tw-rotate-0 tw-opacity-0">&#x25B6;</span>
								</span>
							</span>
						</LinkDark>

						<div>
							<ul className="tw-list-none tw-flex tw-flex-col">
								<li>
									<LinkDark
										onClick={ e => {
											e.preventDefault();
											props?.toggleShowSideNav();
										}}
										href="#"
										className={`${sectionSubheader}|| tw-flex tw-w-full`}
									>
										<span className="tw-border-b tw-border-light || tw-font-semibold || tw-flex-grow tw-flex tw-items-center || tw-mx-6 tw-pb-6 || tw-pointer-events-none">
											<span className="tw-flex tw-shrink-0 tw-mr-3">
												<span className="tw-w-9 tw-transition tw-ease-in-out tw-inline-block tw-text-primary">
													<Avatar className="tw-border-[0.125rem] tw-border-primary" src={ placeholder('200/000000/FFFFFF?text=icon') } />
												</span>
											</span>
											<ArticleHeader className="tw-flex tw-flex-grow tw-shrink-1">
												Client Calendar
											</ArticleHeader>
										</span>
									</LinkDark>
								</li>
								<li>
									<LinkDark
										onClick={ e => {
											e.preventDefault();
											props?.toggleShowSideNav();
										}}
										href="#"
										className={`${sectionSubheader}|| tw-flex tw-w-full || c-toggle-menu-item`}
									>
										<span className="tw-border-b tw-border-light || tw-font-semibold || tw-flex-grow tw-flex tw-items-center || tw-mx-6 tw-py-6 || tw-pointer-events-none">
											<span className="tw-flex tw-shrink-0 tw-mr-3">
												<span className="tw-w-9 tw-transition tw-ease-in-out tw-inline-block tw-text-primary">
													<Avatar className="tw-border-[0.125rem] tw-border-primary" src={ placeholder('200/000000/FFFFFF?text=icon') } />
												</span>
											</span>
											<ArticleHeader className="tw-flex tw-flex-grow tw-shrink-1">
												Supervisor Calendar
											</ArticleHeader>
										</span>
									</LinkDark>
								</li>
							</ul>
						</div>
					</li>

					{/* <li>
						<LinkDark
							onClick={ e => {
								e.preventDefault();
								e.target.classList.toggle('_active');
							}}
							href="#"
							className={`${sectionSubheader}|| tw-flex tw-w-full || c-toggle-menu-item _active`}
						>
							<span className="tw-font-semibold || tw-flex-grow tw-flex tw-items-center  tw-justify-between || tw-px-6 tw-py-3 || tw-pointer-events-none">
								<span className="tw-flex tw-flex-grow tw-shrink-1">
									Schedule Mangement
								</span>
								<span className="tw-flex tw-shrink-0 ">
									<span className="t-ml-3 tw-transition tw-ease-in-out tw-inline-block tw-text-primary tw-rotate-0 tw-opacity-0">&#x25B6;</span>
								</span>
							</span>
						</LinkDark>
					</li> */}

					<li>
						<LinkDark
							onClick={ e => {
								e.preventDefault();
								e.target.classList.toggle('_active');
							}}
							href="#"
							className={`${sectionSubheader}|| tw-flex tw-w-full`}
						>
							<span className="tw-font-semibold || tw-flex-grow tw-flex tw-items-center  tw-justify-between || tw-mx-6 tw-py-xl || tw-pointer-events-none || tw-border-b tw-border-light">
								<span className="tw-flex tw-flex-grow tw-shrink-1">
									Client Management
								</span>
								<span className="tw-flex tw-shrink-0 ">
									<span className="t-ml-3 tw-transition tw-ease-in-out tw-inline-block tw-text-primary tw-rotate-0 tw-opacity-0">&#x25B6;</span>
								</span>
							</span>
						</LinkDark>
					</li>

					<li>
						<LinkDark
							onClick={ e => {
								e.preventDefault();
								e.target.classList.toggle('_active');
							}}
							href="#"
							className={`${sectionSubheader}|| tw-flex tw-w-full`}
						>
							<span className="tw-font-semibold || tw-flex-grow tw-flex tw-items-center  tw-justify-between || tw-mx-6 tw-py-xl || tw-pointer-events-none || tw-border-b tw-border-light">
								<span className="tw-flex tw-flex-grow tw-shrink-1">
									Process
								</span>
								<span className="tw-flex tw-shrink-0 ">
									<span className="t-ml-3 tw-transition tw-ease-in-out tw-inline-block tw-text-primary tw-rotate-0 tw-opacity-0">&#x25B6;</span>
								</span>
							</span>
						</LinkDark>
					</li>
				</ul>
			</div>
			<div className="tw-flex-shrink tw-bg-light-accent/50 tw-border-t tw-border-light-accent/70 tw-flex tw-justify-end tw-px-6 tw-py-3">
				<div className="tw-w-full tw-max-w-[7.5rem]">
					<img alt="Powered by MedForAll" className="tw-w-full tw-block" src="/images/logo_powered-by.svg" />
				</div>
			</div>
		</div>
	</>
}

const MenuItem = props => {
	const location = useLocation();
	const [opened, setOpened] = useState(false);

	const toggleOpen = e => {
		let temp = opened;
		setOpened(temp = !temp);
		console.log("Temp: ",temp, "Opened: ", opened, "Temp=!Temp", temp=!temp);
		e.preventDefault();
	}

	useLayoutEffect(() => {
		setOpened(props.icon && location?.pathname?.split('/')[1].toLowerCase() === props.icon);
	}, [location])

	return <>
		<DefaultButton path={ props.path || null } data-opened={ opened ? true : false } className={`tw-text-dark hover:tw-text-primary-dark tw-border-l-4px ${ opened ? 'tw-bg-primary-accent/10 tw-border-l-primary !tw-text-primary-dark' : 'tw-border-l-transparent' } tw-w-full tw-min-h-40px `} childClasses="tw-flex tw-flex-grow tw-justify-start" onClick={ toggleOpen }>
			{ props.icon && <span className={`tw-w-30px tw-inline-flex tw-justify-center tw-items-center tw-mr-10p tw-ml-20p ${props.icon === 'legacy' || props.icon === 'system' || props.icon === 'client' ? 'tw-opacity-50' : ''}`}>
				<img className="tw-w-20px" src={`/images/icon-menu-${props.icon}${ opened ? '-primary' : '' }.svg`} alt={`${props.icon} Icon`} />
			</span> }
			<span className={`tw-text-4 tw-font-primary-bold tw-flex tw-items-center tw-text-left tw-justify-start tw-flex-grow tw-w-full ${props.icon === 'legacy' || props.icon === 'system' || props.icon === 'client' ? 'tw-opacity-50' : ''}`}>
				{ props.children || "Click me" }
			</span>
			<span className="tw-w-30px tw-inline-flex tw-justify-center tw-items-center tw-mr-10p tw-ml-20p">
				<img className="tw-w-15px" src={`/images/icon-menu-${ opened ? 'close' : 'open' }.svg`} alt="Open Icon Menu" />
			</span>
		</DefaultButton>
	</>
}

const MenuItemLink = props => {
	let activeStyle = {
		textDecoration: "",
	};

	let activeClassName = "tw-border";

	return <>
		<NavLink to={ props.to || '#' } title={ props.title || 'Click me'} className={({ isActive }) =>
              isActive ? 'tw-text-primary !tw-pointer-events-none tw-font-primary-bold tw-flex' : 'tw-text-dark hover:tw-text-primary-dark tw-flex'
		}>
			<DefaultButton tag="span" className={`tw-text-inherit tw-text-in tw-w-full tw-pl-60px ${ props.className }`} childClasses="tw-text-inherit">
				<span className="tw-text-inherit tw-text-in tw-text-left tw-justify-start tw-flex-grow tw-w-full">
					{ props.children || "Click me" }
				</span>
			</DefaultButton>
		</NavLink>
	</>
}


const MainNav = props => {
	const navContext = useAppContext();
	const [fullName, setFullName] = useState('');
	const [profile, setProfile] = useState<GraphProfile>();
	const [profileImage, setProfileImage] = useState<string>();
	let navigate = useNavigate();

	const goHome = async () => {

        navigate("/");
    }

	useLayoutEffect(() => {
		const fetchValidTaken = async () => {
			const token = await getAPIToken();
			const tokenJSON = parseJwt(token);
			console.log(tokenJSON.roles);
			if(!tokenJSON.roles){
				console.log("No Roles Assigned");
				navContext.setShowBilling(false);
				navContext.setShowScheduling(false);
				navContext.setShowTesting(false);
				navContext.setShowDirectCare(false);
				navContext.setShowRemoteSupport(false);
				navContext.setShowHR(false);
				navContext.setScheduleAdmin(false);
				navContext.setSessionScheduleAdmin(false);
				navContext.setHrAdmin(false);
				navContext.setShowProviderAvailability(false);
				navContext.setShowTraining(false);
			}
			if(tokenJSON.roles.includes('GlobalAdministrator')){
				navContext.setShowBilling(true);
				navContext.setShowScheduling(true);
				navContext.setShowTesting(true);
				navContext.setShowHR(true);
				navContext.setShowDirectCare(true);
				navContext.setScheduleAdmin(true);
				navContext.setSessionScheduleAdmin(true);
				navContext.setHrAdmin(true);
				navContext.setShowProviderAvailability(false);
				navContext.setShowTraining(true);
			} else if (tokenJSON.roles.includes('ScheduleCoordinator')){
				navContext.setShowScheduling(true);
				navContext.setScheduleAdmin(true);
				navContext.setSessionScheduleAdmin(true);
				navContext.setShowProviderAvailability(false);
			} else if (tokenJSON.roles.includes('CaseCoordinator')){
				navContext.setShowDirectCare(true);
				navContext.setScheduleAdmin(false);
			} else if (tokenJSON.roles.includes('RSClientCoordinator') || tokenJSON.roles.includes('RemoteSupportCoordinator')) {
				navContext.setShowProviderAvailability(false);
				navContext.setShowRemoteSupport(true);
				navContext.setScheduleAdmin(false);
				navContext.setClientScheduleAdmin(true);
				if(tokenJSON.roles.includes('RemoteSupportCoordinator')){
					navContext.setSessionScheduleAdmin(true);
				}
			}
			 else if (tokenJSON.roles.includes('RemoteSupportQA')){
				navContext.setShowRemoteSupport(true);
				navContext.setScheduleAdmin(false);
				if(tokenJSON.roles.includes('HRManager')){
					navContext.setShowHR(true);
					navContext.setHrAdmin(true);
				}
			} else if (tokenJSON.roles.includes('HRManager')){
				navContext.setShowHR(true);
				navContext.setScheduleAdmin(false);
				navContext.setHrAdmin(true);
			}

			if(tokenJSON.roles.includes('Provider')){
				navContext.setShowProviderAvailability(true);
			}

			if(tokenJSON.roles.includes('TrainingAdministrator')){
				navContext.setShowTraining(true);
			}

		}

		fetchValidTaken()
    		.catch(console.error);
	});

	// useEffect(() => {
	// 	if (navContext?.name?.firstName && navContext?.name?.lastName ) {
	// 		setFullName( navContext?.name?.firstName + ' ' + navContext?.name?.lastName );
	// 	}
	// },[navContext?.name]);

	useEffect(() => {
		const fetchProfile = async () => {
			const data = await getGraphProfile();
			console.log(data);
			setProfile(data);
			navContext.setName({firstName: data.givenName, lastName: data.surname});
			// console.log(navContext);
			setFullName(data.displayName);
		}

		fetchProfile()
    		.catch(console.error);

		const fetchProfileImage = async () => {
			const data = await getGraphImage();
			console.log(data);
			// console.log(navContext);
			navContext.setAvatar('data:image/png;base64, ' + data);
			setProfileImage('data:image/png;base64, ' + data);
		}

		fetchProfileImage()
    		.catch(console.error);
	},[])

	const { handleImageOnLoad, css } = useImageOnLoad();

	const style: { [key: string]: CSSProperties } = {
		wrap: {

		},
		image: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: `100%`,
			height: `100%`,
		}
	}

	return <>
		<div className="tw-flex tw-flex-col tw-h-vh tw-overflow-clip tw-pb-20px">
			<div className="tw-flex-shrink-0 tw-text-center tw-flex tw-flex-col tw-items-center tw-w-full tw-pt-50px">
				<div className="tw-aspect-square tw-rounded-full tw-overflow-hidden tw-w-100px tw-mx-auto tw-mb-10px" >
					<div className="tw-bg-primary-dark tw-rounded-full tw-overflow-hidden tw-max-w-100px tw-max-h-100px tw-aspect-square tw-relative tw-w-full tw-h-full tw-object-cover tw-object-center" style={ style.wrap }>
						<img
							onLoad={ handleImageOnLoad }
							style={{ ...style.image, ...(css.fullSize as CSSProperties) }}
							className="tw-relative tw-w-full tw-h-full tw-object-cover tw-object-center w-max-w-100px tw-max-h-100px  tw-rounded-full " src={ navContext?.avatar } alt={`${ fullName } Avatar`}
						/>
					</div>
					{/* <img className="tw-object-cover tw-h-full tw-w-full tw-cursor-pointer" src={ profileImage } alt={`${ fullName } Avatar`} onClick={goHome}/> */}
				</div>
				<h2 className="tw-flex tw-flex-col tw-space-y-5px">
					<Span className="tw-font-primary-light tw-text-dark-light tw-text-6 tw-leading-none">
						Welcome back!
					</Span>
					<Span className="tw-font-primary-bold tw-text-dark tw-text-3 tw-leading-tight tw-max-w-300px tw-mx-auto tw-block">
						<Span className="tw-overflow-ellipsis tw-overflow-hidden tw-w-full tw-whitespace-nowrap tw-cursor-pointer" onClick={e => {
							e.preventDefault();
							goHome();
						}}>
							{ fullName }
						</Span>
					</Span>
				</h2>
			</div>
			<div className="tw-flex-grow tw-overflow-y-auto tw-overscroll-y-contain tw-py-20px">
				<ul>
					{(navContext.showScheduling || navContext.showDirectCare || navContext.showRemoteSupport) && <li>
						<MenuItem icon="schedule" type="span">
							Schedule
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/schedule/dashboard" title="View Schedule Dashboard">
									Overview
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/schedule/client-requirement" title="View Client Requirements">
									Client Requirements
								</MenuItemLink>
							</li>
							{(navContext.showScheduling || navContext.showRemoteSupport) && <li>
								<MenuItemLink to="/schedule/session-schedule" title="View Session Calendar">
									Session Schedule
								</MenuItemLink>
							</li>}
							{/* {navContext.showTesting && <li>
								<MenuItemLink to="/schedule/client-schedule" title="View Legacy Calendar">
									Legacy Calendar
								</MenuItemLink>
							</li>} */}
							{/* <li>
								<MenuItemLink to="/schedule/direct-support" title="View Direct Support Calendar">
									Direct Support
								</MenuItemLink>
							</li> */}
							{/* <li>
								<MenuItemLink to="/admin/rs-report-management" title="RS Report Management">
									RS Reports
								</MenuItemLink>
							</li> */}
							{/* <li>
								<MenuItemLink to="/admin/outcome-tracking" title="Outcome Tracking">
									Outcome Tracking
								</MenuItemLink>
							</li> */}
							{/* <li>
								<MenuItemLink to="/schedule/co-pilot" title="Scheduling CoPilot">
									Scheduling CoPilot
								</MenuItemLink>
							</li> */}
							{/* {(navContext.showTesting) && <li>
								<MenuItemLink to="/schedule/admin/backgroundJobs" title="Background Jobs">
									Background Jobs
								</MenuItemLink>
							</li>} */}
						</ul>
					</li>}

					{(navContext.showScheduling || navContext.showDirectCare || navContext.showRemoteSupport) && <li>
						<MenuItem icon="admin">
							Administration
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/admin/rs-report-management" title="RS Report Management">
									RS Reports
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/admin/outcome-tracking" title="Outcome Tracking">
									Service Tracking
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/admin/schedule/co-pilot" title="Scheduling CoPilot">
									Scheduling Assistant
								</MenuItemLink>
							</li>
						</ul>
					</li>}
					

					{navContext.showBilling && <li>
						<MenuItem icon="billing">
							Billing
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/billing/claims" title="Generate Claims">
									Generate Claims
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/billing/upload-reports" title="Upload Reports">
									Upload Reports
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/billing/analytics" title="Analytics">
									Claim Analytics
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/billing/metrics" title="Metrics">
									Client Analytics
								</MenuItemLink>
							</li>
						</ul>
					</li>}
					
					{navContext.showProviderAvailability && !navContext.showScheduling && <li>
						<MenuItem icon="schedule">
							Schedule
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/schedule/provider/schedule/session-calendar" title="Remote Support Schedule">
									RS Schedule
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/schedule/availability/providerAvailability" title="Availability Form">
									Availability Forms
								</MenuItemLink>
							</li>

							{/* <li>
								<MenuItemLink to="/provider/remote-support/ticket" title="Remote Support Tickets">
									Support Tickets
								</MenuItemLink>
							</li>

							<li>
								<MenuItemLink to="/provider/training" title="Trainings">
									Trainings
								</MenuItemLink>
							</li> */}


						</ul>
					</li>}
					<li>
						<MenuItem icon="mylearning">
							My Learning
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/myLearning/training" title="Client Trainings">
									Client Trainings
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/myLearning/continuous-education" title="Continuous Education">
									Continuous Education
								</MenuItemLink>
							</li>


						</ul>
					</li>
					{navContext.showProviderAvailability && <li>
						<MenuItem icon="support">
							Support
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/support/tickets" title="Remote Support Tickets">
									Support Tickets
								</MenuItemLink>
							</li>
							{navContext.showTesting &&<li>
								<MenuItemLink to="/not-implemented" title="FAQ">
									FAQ
								</MenuItemLink>
							</li>}


						</ul>
					</li>}
					
					{navContext.showScheduling && <li>
						<MenuItem icon="availability">
							Availability
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/availability/providerAvailability" title="Availability Form">
									Availability Periods
								</MenuItemLink>
							</li>
							{(navContext.showScheduling || navContext.showRemoteSupport) && <li>
								<MenuItemLink to="/availability/submissions" title="Availability Submissions">
									Availability Responses
								</MenuItemLink>
								</li>}

						</ul>
					</li>}
					{(navContext.showHR || navContext.showScheduling || navContext.showRemoteSupport) && <li>
						<MenuItem icon="hr">
							HR
						</MenuItem>
						<ul className="tw-flex-col">
							{navContext.showHR && <li>
								<MenuItemLink to="/hr/coverage-planner" title="Coverage Planner">
									Coverage Planner
								</MenuItemLink>
							</li>}
							<li>
								<MenuItemLink to="/hr/time-off-planner" title="Time-Off Planner">
									Time-Off Planner
								</MenuItemLink>
							</li>
							
						</ul>
					</li>}

					{navContext.showTraining && <li>
						<MenuItem icon="training">
							Training
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/training/client-training-management" title="Client Training">
									Client Training
								</MenuItemLink>
							</li>

							<li>
								<MenuItemLink to="/training/continuous-education-management" title="Continuous Education">
									Continuous Education
								</MenuItemLink>
							</li>
						</ul>
					</li>}

					{navContext.showTesting && <li>
						<MenuItem icon="announcement">
							Announcements
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/announcement/agency" title="Agency Announcement">
									Agency Announcements
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/announcement/clients" title="Client Announcement">
									Client Announcements
								</MenuItemLink>
							</li>

						</ul>
						
					</li>}

					{navContext.showScheduling && <li>
						<MenuItem icon="support">
							Support
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/support/admin/ticketManagement" title="Support Tickets">
									Support Tickets
								</MenuItemLink>
							</li>
						</ul>
					</li>}

					{navContext.showTesting && <li>
						<MenuItem icon="client">
							Client
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/client/client-management" title="Client Management">
									Client Management
								</MenuItemLink>
							</li>
							{/* <li>
								<MenuItemLink to="/client/new" title="New Client">
									New Client
								</MenuItemLink>
							</li> */}
							{/* <li>
								<MenuItemLink to="/client/view" title="View Client">
									View Client
								</MenuItemLink>
							</li> */}
							{/* <li>
								<MenuItemLink to="/client/serviceLocation/new" title="New Location">
									New Location
								</MenuItemLink>
							</li> */}
							
							<li>
								<MenuItemLink to="/client/chat" title="Chat GPT Demo">
									Medforall Copilot
								</MenuItemLink>
							</li>

							<li>
								<MenuItemLink to="/client/co-pilot" title="CoPilot Sandbox">
									CoPilot Sandbox
								</MenuItemLink>
							</li>

							<li>
								<MenuItemLink to="/client/co-pilot-feed" title="CoPilot Feed">
									CoPilot Feed
								</MenuItemLink>
							</li>

							<li>
								<MenuItemLink to="/client/admin/controlCenter" title="Control Center">
									Schedule Planning
								</MenuItemLink>
							</li>

						</ul>
						
					</li>}

					{navContext.showTesting && <li>
						<MenuItem icon="system">
							System
						</MenuItem>
						<ul className="tw-flex-col">
						{(navContext.showTesting) && <li>
								<MenuItemLink to="/system/schedule/backgroundJobs" title="Background Jobs">
									Background Jobs
								</MenuItemLink>
							</li>}
						</ul>
					</li>}

					{navContext.showTesting && <li>
						<MenuItem icon="legacy">
							Legacy
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/legacy/schedule/client-schedule" title="View Legacy Calendar">
									RS Schedule
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/legacy/schedule/direct-support" title="View Direct Support Calendar">
									DS Schedule
								</MenuItemLink>
							</li>
						</ul>
					</li>}
					{/* <li>
						<MenuItem icon="qa">
							Quality Assurance
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/" title="">
									Link 1
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 2
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 3
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 4
								</MenuItemLink>
							</li>
						</ul>
					</li> */}
					{/* <li>
						<MenuItem icon="hr">
							HR
						</MenuItem>
						<ul className="tw-flex-col">
							{ [...Array(100)].map( (item, index) => {
								return <li>
										<MenuItemLink to="/" title="">
											Link {index + 1}
										</MenuItemLink>
									</li>
								})
							}
						</ul>
					</li> */}
					{/* <li>
						<MenuItem icon="analytics">
							Analytics
						</MenuItem>
						<ul className="tw-flex-col">
							<li>
								<MenuItemLink to="/" title="">
									Link 1
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 2
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 3
								</MenuItemLink>
							</li>
							<li>
								<MenuItemLink to="/" title="">
									Link 4
								</MenuItemLink>
							</li>
						</ul>
					</li> */}
				</ul>
			</div>
			<div className="tw-flex-shrink-0">
				{/* <div className="tw-flex tw-justify-center tw-px-40px tw-pt-20px">
					<ButtonTertiary className="tw-grayscale hover:tw-grayscale-0 tw-px-20px tw-min-w-160px" onClick={ e => {
											e.preventDefault();
											// Open Link in new tab
											window.open('https://ohioathome-my.sharepoint.com/:f:/p/srinivas/ErtpdrA0fV1KgatIsZPss7oBl2F5Ve-FQ6Qv015YbCPmkA?e=bo4cB5', '_blank');
											// console.log(props);
											// props?.logout();
										}}>
						<QuestionMarkCircleIcon className="tw-w-20px tw-mr-5px" />
						Help
					</ButtonTertiary>
				</div> */}
				<div className="tw-flex tw-justify-center tw-px-40px tw-py-20px">
					{navContext.showScheduling && !navContext.showProviderAvailability && <ButtonHelp className="tw-px-20px tw-min-w-160px tw-mr-md" onClick={ e => {
											e.preventDefault();
											// Open Link in new tab
											window.open('https://ohioathome-my.sharepoint.com/:f:/p/srinivas/ErtpdrA0fV1KgatIsZPss7oBl2F5Ve-FQ6Qv015YbCPmkA?e=bo4cB5', '_blank');
											// console.log(props);
											// props?.logout();
										}}>
						<QuestionMarkCircleIcon className="tw-w-20px tw-mr-5px" />
						Help
					</ButtonHelp>}

					{navContext.showProviderAvailability && !navContext.showScheduling && <ButtonHelp className="tw-px-20px tw-min-w-160px tw-mr-md" onClick={ e => {
											e.preventDefault();
											// Open Link in new tab
											window.open('https://ohioathome.sharepoint.com/portal2support/Employee%20User%20Guides/Forms/AllItems.aspx', '_blank');
											// console.log(props);
											// props?.logout();
										}
										}>
						<QuestionMarkCircleIcon className="tw-w-20px tw-mr-5px" />
						Help
						</ButtonHelp>
					}

					<ButtonTertiary className="tw-grayscale hover:tw-grayscale-0 tw-px-20px tw-min-w-160px" onClick={ e => {
											e.preventDefault();
											console.log(props);
											props?.logout();
										}}>
						Logout
					</ButtonTertiary>
				</div>
				<div className="tw-flex tw-items-end tw-justify-end tw-px-40px tw-pb-5px">
					<img className="tw-w-120px" src="/images/logo_powered-by.svg" alt="Powered By MedForAll" />
				</div>
			</div>
		</div>
	</>
}

export default MainNav;
