import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import logo from './logo.svg';
import axios, { AxiosRequestConfig } from "axios";
import Product from './types/product';
import './App.css';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from "./utils/NavigationClient";
import { billingRequest, loginRequest } from './authConfig';
import Home from "./pages/Home";
import BillingPage from './pages/Billing';
import vh from './utilities/vhFull';
import NotFound from './pages/NotFound';
import UploadReportsPage from './pages/UploadReports';
import BillingAnalyticsPage from './pages/BillingAnalytics';
import ClientSchedulePage from './pages/ClientSchedule';
import SessionSchedulePage from './pages/SessionSchedule';
import CoverageShiftForm from './pages/CoverageShiftForm';
import SessionForm from './pages/SessionForm';
import CoverageRequirementForm from './pages/CoverageRequirementForm';
import ClientRequirementPage from './pages/ClientRequirement';
import RequirementCalendarPage from './pages/RequirementCalendar';
import NewCoverageRequirementForm from './pages/NewCoverageRequirement';
import LegacySchedulePage from './pages/LegacySchedule';
import LandingPage from './pages/LandingPage';
import { AppProvider, useAppContext } from './Context';
import Core from './templates/Core';
import ClientManagementPage from './pages/ClientManagement';
import NewClientPage from './pages/NewClientForm';
import TestPage from './pages/TestPage';
import EditClientPage from './pages/EditClientPage';
import NewServiceLocationPage from './pages/NewServiceLocationForm';
import NewServicePlanPage from './pages/NewServicePlanForm';
import ChatGPTPage from './pages/ChatGPTPage';
import EditServicePlanPage from './pages/EditServicePlanPage';
import ClientSpanRequirementPage from './pages/Scheduling/ClientRequirement';
import StaffShortageCalendar from './pages/HR/StaffShortageCalendar';
import ClientScheduleManagement from './pages/Scheduling/ClientScheduleManagement';
import TimeOffPlannerCalendar from './pages/HR/TimeOffRequestCalendar';
import RSASchedulingPage from './pages/Scheduling/RSAScheduling';
import ScheduleDashboard from './pages/Scheduling/ScheduleDashboard';
import ErrorPage from './pages/ErrorPage';
import ErrorBoundary from './ErrorBoundary';
import OperationAnalyticsPage from './pages/Analytics/OperationAnalytics';
import AvailabilityForm from './pages/Availability/AvailabilityForm';
import AvailabilityPeriodsPage from './pages/Availability/AvailabilityPeriodsPage';
import AvailabilityResponsesPage from './pages/Availability/AvailabilityResponsesPage';
import AvailabilityPeriodSubmissionsPage from './pages/Availability/AvailabilityPeriodSubmissionsPage';
import AvailabilitySubmission from './pages/Availability/AvailabilitySubmission';
import DirectSupportClientsList from './pages/DirectSupport/DirectSupportClientsList';
import DirectSupportWeeklySchedule from './pages/DirectSupport/DirectSupportWeeklySchedule';
import SchedulingCopilotPage from './pages/Scheduling/SchedulingCopilot';
import AgencyAnnouncementsPage from './pages/Announcements/AgencyAnnouncementsPage';
import ClientAnnouncementsPage from './pages/Announcements/ClientAnnouncementsPage';
import AddAgencyAnnouncementPage from './pages/Announcements/AddAgencyAnnouncementPage';
import AddClientAnnouncementPage from './pages/Announcements/AddClientAnnouncementPage';
import ManageAgencyAnnouncementPage from './pages/Announcements/ManageAgencyAnnouncementPage';
import ManageClientAnnouncementPage from './pages/Announcements/ManageClientAnnouncementPage';
import CoPilotSandboxPage from './pages/CoPilotSandboxPage';
import ProviderSessionSchedulePage from './pages/Provider/SessionSchedule';
import RemoteSupportTicketPage from './pages/Provider/RemoteSupportTicketPage';
import TrainingLandingPage from './pages/Training/TrainingLandingPage';
import CreateTrainingPage from './pages/Training/CreateTrainingPage';
import TrainingPreviewPage from './pages/Training/TrainingPreviewPage';
import ContinuousEducationLandingPage from './pages/Training/ContinuousEducationLandingPage';
import ProviderTrainingLandingPage from './pages/Training/ProviderTrainingLandingPage';
import ProviderCompleteTrainingPage from './pages/Training/ProviderTrainingPage';
import ManageClientTrainingPage from './pages/Training/ManageClientTrainingPage';
import CoPilotFeedSandboxPage from './pages/CoPilotFeedSanboxPage';
import CreateContinuousTrainingPage from './pages/Training/CreateContinuousTrainingPage';
import ManageContinuousEducationPage from './pages/Training/ManageContinuousEducationPage';
import EmployeeContinuousEducationPage from './pages/Training/EmployeeContinuousEducationPage';
import EmpContEdLandingPage from './pages/Training/EmpContEdLandingPage';
import AdminTicketingDashboard from './pages/Ticketing/AdminTicketingDashboard';
import ControlCenterPage from './pages/Administration/ControlCenterPage';
import BackgroundJobsPage from './pages/Administration/BackgroundJobsPage';
import RSReportManagementPage from './pages/RSReportManagement/RSReportManagementPage';
import ClientReportManagementPage from './pages/RSReportManagement/ClientReportManagementPage';
import OutcomeTrackingPage from './pages/Administration/OutcomeTrackingPage';
import MonitoringDashboardPage from './pages/Monitoring/MonitoringDashboardPage';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  const navContext = useAppContext();
  const [hideNav, setHideNav] = React.useState(false);

  const location = useLocation();

  const history = useNavigate();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  useLayoutEffect(() => {
		vh(); // get current page height
    
	}, []);

	useEffect(() => {
    //setHideNav( location.pathname === '/' ? true : false);
		vh();
	}, [location]);

  const handleLogout = () => {
    pca.logoutRedirect();
  }

  const authProps = {
		handleLogout
	}

  const sharedAttributes = {
		setHideNav, hideNav, ...authProps
	}

  function randomGen() {
    throw new Error('Function not implemented.');
  }

  //const navContext = useAppContext();

	// const location = useLocation();
	// // The next 3-4 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
	// // const history = useHistory();
	// const history = useNavigate();
	// const navigationClient = new CustomNavigationClient(history);
	// pca.setNavigationClient(navigationClient);
	// const [hideNav, setHideNav] = useState(false);

	// useLayoutEffect(() => {
	// 	vh(); // get current page height
	// }, []);

	// useEffect(() => {
	// 	setHideNav( location.pathname === '/' ? true : false);
	// 	vh();
	// }, [location]);


	// const authProps = {
	// 	setHideNav, hideNav
	// }

  const navigate = useNavigate();

  return (
    <AppProvider>
         <MsalProvider instance={pca}>

              <AuthenticatedTemplate>
                <Core hideNav={ hideNav } {...sharedAttributes}>
                
                  <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/admin/monitoring" element={<MonitoringDashboardPage {...authProps} />} />
                    <Route path="/admin/outcome-tracking" element={<OutcomeTrackingPage {...authProps} />} />
                    <Route path="/admin/rs-report-management" element={<RSReportManagementPage { ...authProps } />} />
                    <Route path="/admin/client-report-management" element={<ClientReportManagementPage { ...authProps } />} />
                    <Route path="/schedule/schedule-mgmt/:patientID/weekly-schedule" element={<ClientScheduleManagement { ...authProps } key={Math.random()}/>} />
                    <Route path="/schedule/schedule-mgmt/:patientID/weekly-schedule/:weekStart/:weekEnd" element={<ClientScheduleManagement { ...authProps } key={Math.random()}/>} />
                    <Route path="/billing/claims" element={<BillingPage { ...authProps } />} />
                    <Route path="/billing/upload-reports" element={<UploadReportsPage { ...authProps } />} />
                    <Route path="/billing/analytics" element={<BillingAnalyticsPage { ...authProps } />} />
                    <Route path="/billing/metrics" element={<OperationAnalyticsPage { ...authProps } />} />
                    <Route path="/legacy/schedule/client-schedule" element={<ClientSchedulePage { ...authProps }/>}/>
                    <Route path="/admin/schedule/co-pilot" element={<SchedulingCopilotPage { ...authProps }/>}/>
                    <Route path="/schedule/client-schedule/:patientID/legacy" element={<LegacySchedulePage { ...authProps } />} />
                    <Route path="/schedule/client-schedule/:patientID/new" element={<CoverageShiftForm { ...authProps } />} />
                    <Route path="/schedule/client-requirement" element={<ClientRequirementPage { ...authProps } />} />
                    <Route path="/schedule/client-requirement/:patientID/requirement" element={<RequirementCalendarPage { ...authProps } />} />
                    <Route path="/schedule/client-requirement/:patientID/requirement/:weekStart/:weekEnd" element={<RequirementCalendarPage { ...authProps } key={Math.random()}/>} />
                    <Route path="/schedule/client-requirement/:patientID/requirement-form" element={<CoverageRequirementForm { ...authProps } />} />
                    <Route path="/schedule/client-requirement/:patientID/span-requirement" element={<ClientSpanRequirementPage { ...authProps } />} />
                    <Route path="/schedule/client-requirement/:patientID/New" element={<NewCoverageRequirementForm { ...authProps } />} />     
                    <Route path="/legacy/schedule/direct-support" element={<DirectSupportClientsList { ...authProps }/>}/>"   
                    <Route path="/legacy/schedule/direct-support/:patientID/weekly-schedule" element={<DirectSupportWeeklySchedule { ...authProps } key={Math.random()}/>} />"
                    <Route path="/legacy/schedule/direct-support/:patientID/weekly-schedule/:weekStart/:weekEnd" element={<DirectSupportWeeklySchedule { ...authProps } key={Math.random()}/>} />         
                    <Route path="/schedule/session-schedule" element={<SessionSchedulePage { ...authProps } />} />
                    <Route path="/schedule/session-schedule/:weekStart/:weekEnd" element={<SessionSchedulePage { ...authProps } key={Math.random()}/>} />
                    <Route path="/schedule/session-schedule/new" element={<SessionForm { ...authProps } />} />
                    <Route path="/schedule/session-calendar" element={<RSASchedulingPage { ...authProps } />} />
                    <Route path="/schedule/session-calendar/:calendarType" element={<RSASchedulingPage {...sharedAttributes} />} />
                    <Route path="/schedule/dashboard" element={<ScheduleDashboard {...sharedAttributes} />} />
                    <Route path="/client/client-management" element={<ClientManagementPage />} />
                    <Route path="/client/add" element={<NewClientPage />} />
                    <Route path="/client/:clientID" element={<EditClientPage />} />
                    <Route path="/client/:clientID/serviceLocation/new" element={<NewServiceLocationPage />} />
                    <Route path="/client/:clientID/servicePlan/new" element={<NewServicePlanPage />} />
                    <Route path="/client/:clientID/servicePlan/:planID/edit" element={<EditServicePlanPage />} />
                    <Route path="/client/chat" element={<ChatGPTPage />} />
                    <Route path="/client/co-pilot" element={<CoPilotSandboxPage {...authProps} />} key={Math.random()}/>
                    <Route path="/client/co-pilot-feed" element={<CoPilotFeedSandboxPage {...authProps} />} key={Math.random()}/>
                    <Route path="/client/co-pilot/:chatID" element={<CoPilotSandboxPage {...authProps} />} key={Math.random()}/>
                    <Route path="/hr/coverage-planner" element={<StaffShortageCalendar />} />
                    <Route path="/hr/time-off-planner" element={<TimeOffPlannerCalendar />} />
                    <Route path="/" element={<LandingPage { ...authProps } />} />
                    <Route path="/testing" element={<TestPage {...authProps} />} />
                    <Route path="/schedule/availability/providerAvailability" element={<AvailabilityPeriodsPage {...authProps} />} />
                    <Route path="/availability/providerAvailability" element={<AvailabilityPeriodsPage {...authProps} />} />
                    <Route path="/availability/submissions" element={<AvailabilityResponsesPage {...authProps} />} />
                    <Route path="/availability/providerAvailability/:periodID/form" element={<AvailabilityForm {...authProps} />} />
                    <Route path="/availability/submissions/:periodID/submissions" element={<AvailabilityPeriodSubmissionsPage {...authProps} />} />
                    <Route path="/availability/submissions/:periodID/submissions/:submissionID" element={<AvailabilitySubmission {...authProps} />} />

                    <Route path="/announcement/agency" element={<AgencyAnnouncementsPage {...authProps} />} />
                    <Route path="/announcement/agency/add" element={<AddAgencyAnnouncementPage {...authProps} />} />
                    <Route path="/announcement/agency/:announcementID/edit" element={<ManageAgencyAnnouncementPage {...authProps} />} />
                    <Route path="/announcement/clients" element={<ClientAnnouncementsPage {...authProps} />} />
                    <Route path="/announcement/clients/add" element={<AddClientAnnouncementPage {...authProps} />} />
                    <Route path="/announcement/clients/:announcementID/edit" element={<ManageClientAnnouncementPage {...authProps} />} />

                    <Route path="/schedule/provider/schedule/session-calendar/:weekStart/:weekEnd" element={<ProviderSessionSchedulePage {...authProps} />} key={Math.random()}/>
                    <Route path="/schedule/provider/schedule/session-calendar" element={<ProviderSessionSchedulePage {...authProps}/>} key={Math.random()}/>

                    <Route path="/support/tickets" element={<RemoteSupportTicketPage {...authProps} />} key={Math.random()}/>
                    <Route path="/support/tickets/:ticketID" element={<RemoteSupportTicketPage {...authProps} />} key={Math.random()}/>

                    <Route path="/myLearning/training" element={<ProviderTrainingLandingPage {...authProps} />} />
                    <Route path="/myLearning/continuous-education" element={<EmpContEdLandingPage {...authProps} />} />

                    <Route path="/provider/training/complete" element={<ProviderCompleteTrainingPage {...authProps} />} />

                    <Route path="/training/client-training-management" element={<TrainingLandingPage {...authProps} />} key={Math.random()}/>
                    <Route path="/training/client-training-management/:status" element={<TrainingLandingPage {...authProps} />}  key={Math.random()}/>
                    <Route path="/training/continuous-education-management" element={<ContinuousEducationLandingPage {...authProps} />}  key={Math.random()} />
                    <Route path="/training/continuous-education-management/:status" element={<ContinuousEducationLandingPage {...authProps} />}  key={Math.random()}/>
                    <Route path="/training/training-management/create" element={<CreateTrainingPage {...authProps} />} />
                    <Route path="/training/training-management/preview" element={<TrainingPreviewPage {...authProps} />} />
                    <Route path="/training/client-training-management/:trainingID/manage" element={<ManageClientTrainingPage {...authProps} />} />
                    <Route path="/training/continuous-education-management/:trainingID/manage" element={<ManageContinuousEducationPage {...authProps} />} />

                    <Route path="/training/continuous-education-management/create" element={<CreateContinuousTrainingPage {...authProps} />} />

                    <Route path="/provider/training/:trainingID/:trainingMode" element={<ProviderCompleteTrainingPage {...authProps} />} />

                    <Route path="/employee/continuous-education/:trainingID/:trainingMode" element={<EmployeeContinuousEducationPage {...authProps} />} />

                    <Route path='/support/admin/ticketManagement' element={<AdminTicketingDashboard {...authProps} />} />

                    <Route path='/client/admin/controlCenter' element={<ControlCenterPage {...authProps} />} />

                    <Route path='/system/schedule/backgroundJobs' element={<BackgroundJobsPage {...authProps} />} />

                  </Routes>
                </Core>

                            {/* <div className="App">
                              <header className="App-header">
                                <img src={logo} className="App-logo" alt="logo" />
                                <form onSubmit={handleSubmit}>
                                  <p>DODD Provider Repor Upload</p>
                                  <input type="file" multiple={true} onChange={handleChange} ref={ref}/>
                                  <button type="submit">Upload</button>
                                </form>
                                
                                <button onClick={handleLogout}>Logout</button>
                                </header>
                            </div> */}
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                            {/* <button onClick={handleLogin}>Login</button> */}
                  <Home pca={pca}/>
              </UnauthenticatedTemplate> 


          </MsalProvider>
    </AppProvider>
   
  
  );
}

const getProducts = async (): Promise<Product[]> => {
  const url = "https://my-container-app.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/products";
  return axios.get<Product[]>(url,{
    validateStatus: function (status) {
        // if this function returns true, exception is not thrown, so
        // in simplest case just return true to handle status checks externally.
        return true;
    }}).then(response => response.data);
}

const addProduct = async (product: Product): Promise<Product[]> => {
  const url = "https://my-container-app.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/products";
  return axios.post<Product[]>(url, product,{
    validateStatus: function (status) {
        // if this function returns true, exception is not thrown, so
        // in simplest case just return true to handle status checks externally.
        return true;
    }}).then(response => response.data);
}

const updateProduct = async (product: Product): Promise<Product[]> => {
  const url = `https://my-container-app.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/products/${product.id}`;
  return axios.put<Product[]>(url, product,{
    validateStatus: function (status) {
        // if this function returns true, exception is not thrown, so
        // in simplest case just return true to handle status checks externally.
        return true;
    }}).then(response => response.data);
}

const deleteProduct = async (product: Product): Promise<Product[]> => {
  const url = `https://my-container-app.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/products/${product.id}`;
  return axios.delete<Product[]>(url,{
    validateStatus: function (status) {
        // if this function returns true, exception is not thrown, so
        // in simplest case just return true to handle status checks externally.
        return true;
    }}).then(response => response.data);
}


export default App;

