import React, { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";

import Patient from "../../types/patient";
import { getActiveClients, getClientActiveISPByID, getClientByID, getOutcomeStepsForPlan, getPrimaryHomeForClient, getServicesForPlan } from "../../apicalls/schedulingService";
import { createExcel, createExcelWithRealData, createExcelWithRealDataAndCompletedServices, createExcelWithRealDataAndCompletedServicesForDateSpan, getCompletedOutcomeStepsForClientForDateSpan, getCompletedServicesForClientForDateSpan, getSupportDocumentationForClientForDateSpan } from "../../apicalls/outcomeTrackingService";
import { set } from "lodash";
import moment from "moment";
import CalendarSelector from "../../components/calendars/CalendarSelector";

const OutcomeTrackingPage = props => {
    const appContext = useAppContext();

    const [clientsLoading, setClientsLoading] = useState(true);
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [selectedClient, setSelectedClient] = useState<string>("");
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [downloading, setDownloading] = useState(false);
    const [dateMode, setDateMode] = useState<'monthly' | 'weekly' | 'datespan'>('monthly');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [selectedWeek, setSelectedWeek] = useState<string>('');

    useLayoutEffect(() => {
        document.title = 'Outcome Tracking';

        const fetchClients = async () => {
            const data = await getActiveClients(appContext.handleAPICallError);
            console.log(data);
            const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
            setActivePatients(activePatients);
            setClientsLoading(false);
        }

        fetchClients().catch(console.error);
    }, []);

    const handleDownload = async () => {
        setDownloading(true);
        // Implement your download logic here
        // Call the function with the required parameters
        const client = await getClientByID(selectedClient, appContext.handleAPICallError);
        console.log(client);

        var serviceStartDate = moment(startDate).format("MM-DD-YYYY");
        var serviceEndDate = moment(endDate).format("MM-DD-YYYY");

        if (dateMode === 'monthly'){
            serviceStartDate = moment(selectedMonth + " 1, " + selectedYear).format("MM-DD-YYYY");
            serviceEndDate = moment(selectedMonth + " 1, " + selectedYear).add(1, 'month').subtract(1, 'day').format("MM-DD-YYYY");
        } else if (dateMode === 'datespan'){
            serviceStartDate = moment(startDate).format("MM-DD-YYYY");
            serviceEndDate = moment(endDate).format("MM-DD-YYYY");
        }

        const completedServices = await getCompletedServicesForClientForDateSpan(selectedClient, serviceStartDate, serviceEndDate, appContext.handleAPICallError);

        console.log(completedServices);

        const completedOutcomes = await getCompletedOutcomeStepsForClientForDateSpan(selectedClient, serviceStartDate, serviceEndDate, appContext.handleAPICallError);

        console.log(completedOutcomes);

        const servicePlan = await getClientActiveISPByID(selectedClient, appContext.handleAPICallError);
        console.log(servicePlan);

        const primaryHome = await getPrimaryHomeForClient(selectedClient, appContext.handleAPICallError);
        console.log(primaryHome);

        if(servicePlan){
            const services = await getServicesForPlan(servicePlan.planID, appContext.handleAPICallError);
            console.log(services);

            const outcomes = await getOutcomeStepsForPlan(servicePlan.planID, appContext.handleAPICallError);
            console.log("OUTCOMES");
            console.log(outcomes);

            if (dateMode === 'monthly'){
                createExcelWithRealDataAndCompletedServices(selectedMonth, selectedYear, client, primaryHome, services, outcomes, completedServices, completedOutcomes);
            } else if (dateMode === 'datespan'){
                const supportDocumentation = await getSupportDocumentationForClientForDateSpan(selectedClient, serviceStartDate, serviceEndDate, appContext.handleAPICallError);
                console.log(supportDocumentation);

                createExcelWithRealDataAndCompletedServicesForDateSpan(serviceStartDate, serviceEndDate, client, primaryHome, services, outcomes, completedServices, completedOutcomes, supportDocumentation);
            }

            //createExcelWithRealData(selectedMonth, selectedYear, client, primaryHome, services, outcomes);
        }

        // const clientName = activePatients.find(patient => patient.patientID === selectedClient)?.firstName + " " + activePatients.find(patient => patient.patientID === selectedClient)?.lastName;
    	// createExcel(selectedMonth, selectedYear, clientName);
        //await new Promise(resolve => setTimeout(resolve, 2000)); // Simulating download
        setDownloading(false);
    };

    const handleClear = () => {
        setSelectedClient("");
        setSelectedMonth("");
        setSelectedYear("");
        setDownloading(false);
    };

    const isFormComplete = selectedClient && (
        (dateMode === 'monthly' && selectedMonth && selectedYear) ||
        (dateMode === 'weekly' && selectedWeek) ||
        (dateMode === 'datespan' && startDate && endDate)
    );

    return (
        <div className="tw-container tw-mx-auto tw-p-4">
            <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Client Service Tracking</h1>

            {clientsLoading ? (
                <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
                    <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-blue-500"></div>
                </div>
            ) : (
                <div className="tw-space-y-4">
                    <select
                        className="tw-w-full tw-p-2 tw-border tw-rounded"
                        value={selectedClient}
                        onChange={(e) => setSelectedClient(e.target.value)}
                    >
                        <option value="">Select a client</option>
                        {activePatients.map((patient) => (
                            <option key={patient.patientID} value={patient.patientID}>
                                {patient.firstName} {patient.lastName}
                            </option>
                        ))}
                    </select>

                    <div className="tw-flex tw-flex-col tw-space-x-4">
                        <select
                        className="tw-w-1/3 tw-p-2 tw-border tw-rounded"
                        value={dateMode}
                        onChange={(e) => setDateMode(e.target.value as 'monthly' | 'weekly' | 'datespan')}
                    >
                        <option value="monthly">Monthly</option>
                        {/* <option value="weekly">Weekly</option> */}
                            <option value="datespan">Date Range</option>
                        </select>

                        {dateMode === 'monthly' && (
                        <div className="tw-flex tw-space-x-4 tw-mt-4">
                            <select
                                className="tw-w-1/3 tw-p-2 tw-border tw-rounded"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                <option value="">Select a month</option>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                                    <option key={month} value={month}>
                                        {new Date(2000, month - 1, 1).toLocaleString('default', { month: 'long' })}
                                    </option>
                                ))}
                            </select>

                            <select
                                className="tw-w-1/3 tw-p-2 tw-border tw-rounded"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                <option value="">Select a year</option>
                                {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {dateMode === 'datespan' && (
                        <div className="tw-flex tw-space-x-4 tw-mt-4">
                            <CalendarSelector id="startDate" name="Start Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setStartDate(e.target.value);
											}}/>
                            <CalendarSelector id="endDate" name="End Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setEndDate(e.target.value);
											}}/>
                            
                            {/* <input
                                type="date"
                                className="tw-w-full tw-p-2 tw-border tw-rounded"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <input
                                type="date"
                                className="tw-w-full tw-p-2 tw-border tw-rounded"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            /> */}
                        </div>
                    )}
                    </div>

                   

                    

                    <div className="tw-flex tw-space-x-4">
                        <button
                            className={`tw-px-4 tw-py-2 tw-rounded ${
                                isFormComplete
                                    ? "tw-bg-primary tw-text-white"
                                    : "tw-bg-gray-300 tw-text-gray-500 tw-cursor-not-allowed"
                            }`}
                            onClick={handleDownload}
                            disabled={!isFormComplete || downloading}
                        >
                            {downloading ? (
                                <span className="tw-flex tw-items-center">
                                    <svg className="tw-animate-spin tw-h-5 tw-w-5 tw-mr-3" viewBox="0 0 24 24">
                                        <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                        <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                    </svg>
                                    Downloading...
                                </span>
                            ) : (
                                "Download Summary"
                            )}
                        </button>
                        <button
                            className="tw-px-4 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded"
                            onClick={handleClear}
                        >
                            Clear
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OutcomeTrackingPage;
